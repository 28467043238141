@import "src/styles";
@mixin fluidTypography() {
  @include levelTypography('level4', $color-black, $font-weight-normal);
  @include breakpoint-sm {
    @include bodyTypography(body2);
  }
}

.initialScreenContainer {
  display: flex;
  flex-direction: column;

  & .countrySelectToggle {
    border-bottom: 1px solid $color-gray-50;
    margin-bottom: $spacing-16;
    padding-bottom: $spacing-16;
  }

  & span {
    @include fluidTypography();
  }

  & > span {
    font-weight: $font-weight-bold;
    margin-bottom: $spacing-32;

    @include breakpoint-sm {
      margin-bottom: $spacing-16;
    }
  }
}

.countriesContainer {
  display: flex;
  flex-direction: column;

  span {
    @include fluidTypography();
  }

  & .countriesBackBtn {
    margin-bottom: $spacing-16;
  }

  & .countriesContainerRadioGroup {
    margin: $spacing-12 0 0 $spacing-8;
  }

  & .oneLangBtnContainer {
    & button {
      width: 100%;
    }
  }

  & .accordion {
    overflow-y: auto;
    width: calc(100% + 34px);
    margin-left: -17px;
    max-height: 262px;
    padding: 0;

    & > * {
      padding: $spacing-8 $spacing-16;
      border: 0;

      &:not(:last-child)::after {
        content: '';
        display: block;
        width: 100%;
        margin-top: $spacing-16;
        border-bottom: 1px solid $color-gray-50;
      }
    }

    & .accordionItem {
      & button {
        align-items: center;
        width: 100%;

        & .accordionIcon {
          display: flex;
          width: 18px;
          height: 18px;
          transition: 150ms ease-in-out;
        }

        &[aria-expanded='true'] .accordionIcon {
          transform: rotate(45deg);
        }

        &[aria-expanded='false'] .accordionIcon {
          transform: rotate(0);
        }
      }
    }
  }
}

.countrySelectToggle {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  pointer-events: all;

  &:focus {
    outline: none;
  }

  &:hover {
    .accordionIcon,
    .countrySelectToggleLabel {
      color: $color-primary-main;
    }
  }

  & .countrySelectToggleIcon {
    display: flex;
    width: 18px;
    height: 18px;
  }

  & .countrySelectToggleLabel {
    @include fluidTypography();
    margin-left: $spacing-12;
    flex: 1 1 0%;

    &.selected {
      font-weight: $font-weight-bold;
    }
  }

  & .countrySelectToggleArrow {
    fill: $color-black;
    margin-left: $spacing-8 / 2;
    width: 12px;
    transform: rotate(270deg);
  }
}

.radioGroup {
  display: flex;
  flex-direction: column;
  row-gap: $spacing-16;

  @include breakpoint-sm {
    row-gap: $spacing-12;
  }

  .radioLabel {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    > span {
      font-weight: normal;
    }

    .radioIcon {
      fill: $color-gray-100;
      transition: fill 100ms ease;
      margin-right: $spacing-8;
      flex: none;
      height: 20px;
      width: 20px;

      @include breakpoint-sm {
        height: 18px;
        width: 18px;
      }
    }

    &.selected {
      .radioIcon {
        fill: $color-primary-main;
      }
    }
  }
}

.accordionIcon {
  color: $color-gray-200;
  transition: color 150ms ease;

  &.selected {
    color: $color-primary-main;
  }
}
