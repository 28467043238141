@import "src/styles";
.popup {
  position: absolute;
  top: auto;
  bottom: 100%;
  right: 0;
  width: 230px;
  background: $color-white;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  z-index: $z-index-6;
  padding: $spacing-16;
}
