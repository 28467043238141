@import "src/styles";
.heading {
  margin-bottom: $spacing-24;
}

.privacyText {
  & > a {
    text-decoration: underline;
    & > * {
      white-space: nowrap;
      font-weight: $font-weight-normal;
    }
  }
}
