@import "src/styles";
.container {
  @include containerLayout();
}
.mobileOnly {
  display: block;

  @include breakpoint-md {
    display: none;
  }
}
