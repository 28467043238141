@import "src/styles";
.container {
  display: flex;
  align-items: center;
  gap: $spacing-12;
}

.iconContainer {
  display: flex;
  justify-content: center;
  width: 34px;
  height: 24px;
  padding: $spacing-4;
  background: $color-white;
  border: $form-field-border;
  border-radius: $border-radius;
}
