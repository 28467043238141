$spacing-2: 2px;
$spacing-4: 4px;
$spacing-8: 8px;
$spacing-12: 12px;
$spacing-16: 16px;
$spacing-24: 24px;
$spacing-32: 32px;
$spacing-40: 40px;
$spacing-48: 48px;
$spacing-64: 64px;
$spacing-96: 96px;
$spacing-160: 160px;
$spacing-navBar: 50px;
$spacing-blueBar-m: 48px;

$layout-max-width: 1320px;
$layout-padding-tablet: 30px;
$layout-padding-mobile: $spacing-16;
$layout-padding-desktop: 80px;
$layout-grid-gap: 30px;
$border-radius: 3px;
