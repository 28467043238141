@import "src/styles";
.link {
  text-decoration: none;

  &.level3 {
    @include levelTypography(level3);
  }

  &.level4 {
    @include levelTypography(level4);
  }

  &.level5 {
    @include levelTypography(level5);
  }

  &.body1 {
    @include bodyTypography(body1);
  }

  &.body2 {
    @include bodyTypography(body2);
  }

  &.primary {
    color: $color-primary-main;

    &:hover {
      text-decoration: underline;
    }
  }

  &.white {
    color: $color-white;

    &:hover {
      text-decoration: underline;
    }
  }
}

.withArrow {
  svg {
    box-sizing: content-box;
    transition: padding-left 200ms ease, padding-right 200ms ease;
    fill: $color-primary-main;
    padding-left: $spacing-8;
    padding-right: $spacing-8;
    margin-right: $spacing-2;
    width: 20px;
    height: 12px;
    overflow: visible;
    vertical-align: baseline;
  }

  &:hover {
    svg {
      padding-left: $spacing-16;
      padding-right: 0;
    }
  }
}
