@import "src/styles";
.drawer {
  overflow: hidden;
  z-index: $z-index-6;
  background: rgba(0, 0, 0, 0.33);
  position: fixed;
  inset: 0;

  & [data-dialog-content] {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    background: $color-white;
  }
}
