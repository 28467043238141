@import "src/styles";
.container {
  position: relative;
  display: inline-block;

  & .chevronDown {
    fill: $color-white;
    margin-left: $spacing-8 / 2;
    transition: transform 150ms ease-in;
    width: 12px;

    &.expanded {
      transform: rotate(180deg);
    }
  }

  &:hover {
    text-decoration: underline;
    text-decoration-color: $color-white;
  }
}

.drawer {
  overflow-y: auto;
  z-index: $z-index-6;

  & [data-dialog-content] {
    margin: 0;
    padding: 0;
    @include breakpoint-sm {
      max-width: 460px;
    }

    & .drawerCrossBtn {
      align-self: flex-end;
    }

    & [data-reach-accordion] {
      & > * {
        padding: $spacing-8 0;
      }
    }

    & > div:last-child {
      padding: 0 $spacing-16 $spacing-16 $spacing-16;
      overflow-x: hidden;
      overflow-y: auto;
      flex: 1 1 auto;
    }

    & .buttonGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $spacing-16;

      & .drawerCrossBtn {
        margin-left: auto;
      }
    }

    & .countriesListTitle {
      padding: 0 $spacing-16 0 $spacing-16;

      & > p {
        font-weight: bold;
        margin-bottom: $spacing-24;
      }
    }
  }
}
