@import "src/styles";
.toggle {
  display: flex;
  align-items: center;
  user-select: none;

  & .languageCode {
    font-weight: bold;
  }

  & .flagIcon {
    display: flex;
    width: 20px;
    height: 20px;
    margin-right: $spacing-8;
  }
}
