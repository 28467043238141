@import "src/styles";
.button {
  display: flex;
  align-items: center;

  > svg {
    fill: $color-black;
    width: 12px;
    transform: rotate(90deg);
  }

  > span {
    @include levelTypography('level4', $color-black, $font-weight-normal);
    margin-left: $spacing-8;
    @include breakpoint-sm {
      @include bodyTypography(body2);
    }
  }
}
