@mixin containerLayout($paddingMobile: $layout-padding-mobile) {
  max-width: $layout-max-width;
  margin: 0 auto;
  padding: {
    left: $paddingMobile;
    right: $paddingMobile;
  }

  @include breakpoint-md {
    padding: {
      left: $layout-padding-tablet;
      right: $layout-padding-tablet;
    }
  }

  @include breakpoint-lg {
    padding: {
      left: $layout-padding-desktop;
      right: $layout-padding-desktop;
    }
  }
}
