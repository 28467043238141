$color-primary-main: #00a9e0;
$color-primary-dark: #009dd0;

$color-secondary-main: #74eeb5;
$color-secondary-dark: #2ddea9;

$color-error-light: #feedee;
$color-error-main: #ef5564;

$color-warning-light: #fff1da;
$color-warning-main: #fea106;

$color-success-main: #28a745;

$color-black: #000000;
$color-white: #ffffff;

$color-gray-50: #f8f9f9;
$color-gray-100: #dfdfdf;
$color-gray-200: #a6a6a6;
$color-gray-300: #979797;

$color-sub-footer: #eef7fa;

$color-input-error-bg: #fff1da;
$chrome-default-placeholder-color: #a9a9a9;

$box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
$box-shadow-2: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
$box-shadow-3: 0px -1px 20px rgba(0, 0, 0, 0.15);
