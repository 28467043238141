@import "src/styles";
.banner {
  position: sticky;
  background: $color-primary-main;
  display: flex;
  align-items: center;
}

.content {
  @include containerLayout();
  display: grid;
  align-items: center;
  grid-template-columns: $spacing-24 auto $spacing-24;
  width: 100%;
  padding: $spacing-16;
  line-height: $spacing-24;

  @media (min-width: $breakpoint-sm) {
    grid-template-columns: $spacing-64 auto $spacing-64;
  }
}

#link {
  margin-left: $spacing-8;
  text-decoration: underline;
  color: inherit;
}

.text {
  grid-column: 2;
  text-align: center;
}

.closeIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-sm) {
    align-self: start;
  }
}

.closeIcon {
  grid-column: 3;
  fill: $color-white;
  height: 18px;
  width: 18px;
  cursor: pointer;

  @include breakpoint-sm {
    height: 24px;
    width: 24px;
  }
}
