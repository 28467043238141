$breakpoint-xs: 320px;
$breakpoint-sm: 544px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1366px;

@mixin breakpoint-xs {
  @media (max-width: $breakpoint-xs) {
    @content;
  }
}
@mixin breakpoint-sm {
  @media (min-width: $breakpoint-sm) {
    @content;
  }
}
@mixin breakpoint-md {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}
@mixin breakpoint-lg {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}
@mixin breakpoint-xl {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}
