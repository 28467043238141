@import "src/styles";
.groupLabel {
  display: block;
  margin-bottom: $spacing-8;
}

.focus {
  outline: 2px solid $color-warning-main;
  outline-offset: 2px;
}

.label {
  display: inline-flex;
  align-items: center;

  .icon {
    fill: $color-gray-100;
    transition: fill 100ms ease;
    margin-right: $spacing-8;
    flex: none;
  }

  &.selected {
    .icon {
      fill: $color-primary-main;
    }
  }

  &.thickActiveBorder {
    box-shadow: inset 0px 0px 0px 1px $color-primary-main;
  }
}
