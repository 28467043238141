@import "src/styles";
.level1 {
  @include levelTypography(level1);
}

.level2 {
  @include levelTypography(level2);
}

.level3 {
  @include levelTypography(level3);
}

.level4 {
  @include levelTypography(level4);
}

.level5 {
  @include levelTypography(level5);
}

.body1 {
  @include bodyTypography(body1);
}

.body2 {
  @include bodyTypography(body2);
}

.body3 {
  @include bodyTypography(body3);
}

.default {
  color: $color-black;
}

.primary {
  color: $color-primary-main;
}

.white {
  color: $color-white;
}

.warning {
  color: $color-warning-main;
}

.error {
  color: $color-error-main;
}

.success {
  color: $color-success-main;
}

.strike_through {
  color: $color-gray-200;
  text-decoration: line-through;
}

.li {
  margin: unset;
  padding: unset;
}
