@import "src/styles";
.button {
  @include bodyTypography(body1, $color-black, $font-weight-bold);
  position: relative;
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
  border-color: transparent;
  text-decoration: none;
  text-align: center;
  min-height: 50px;
  a {
    display: inline-block;
  }

  &.text {
    transition: color 100ms ease;

    &.primary {
      color: $color-primary-main;
      &:hover,
      &:active {
        &:not(.disableHover):not([disabled]) {
          color: $color-primary-dark;
        }
      }
    }

    &.secondary {
      color: $color-secondary-main;

      &:hover,
      &:active {
        &:not(.disableHover):not([disabled]) {
          color: $color-secondary-dark;
        }
      }
    }

    &.default {
      color: $color-black;
    }

    &.gray {
      color: $color-gray-200;
    }

    &.disabled {
      color: $color-gray-200;
      cursor: not-allowed;
    }
  }

  &.contained {
    padding: $spacing-12 $spacing-24;
    transition: background-color 100ms ease, border-color 100ms ease;

    &.default {
      background-color: $color-white;
      border-color: $color-white;
      color: $color-black;
      &:hover,
      &:active {
        &:not(.disableHover):not([disabled]) {
          background-color: $color-gray-100;
          border-color: $color-gray-100;
        }
      }
    }

    &.primary {
      background-color: $color-primary-main;
      border-color: $color-primary-main;
      color: $color-white;

      &:hover,
      &:active {
        &:not(.disableHover):not([disabled]) {
          background-color: $color-primary-dark;
          border-color: $color-primary-dark;
        }
      }
    }

    &.secondary {
      background-color: $color-secondary-main;
      border-color: $color-secondary-main;
      color: $color-black;

      &:hover,
      &:active {
        &:not(.disableHover):not([disabled]) {
          background-color: $color-secondary-dark;
          border-color: $color-secondary-dark;
        }
      }
    }

    &.gray {
      background-color: $color-gray-100;
      border-color: $color-gray-100;

      &:hover,
      &:active {
        &:not(.disableHover):not([disabled]) {
          background-color: $color-gray-200;
          border-color: $color-gray-200;
        }
      }
    }

    &.disabled {
      background-color: $color-gray-100;
      border-color: $color-gray-100;
      color: $color-gray-200;
      cursor: not-allowed;
    }
  }

  &.outlined {
    padding: $spacing-12 $spacing-24;

    &.default {
      border-color: $color-black;
      background-color: $color-white;

      &:hover,
      &:active {
        &:not(.disableHover):not([disabled]) {
          border-color: $color-gray-200;
        }
      }
    }

    &.primary {
      border-color: $color-primary-main;
      background-color: $color-white;
      &:hover,
      &:active {
        &:not(.disableHover):not([disabled]) {
          border-color: $color-primary-dark;
        }
      }
    }

    &.secondary {
      border-color: $color-secondary-main;
      background-color: $color-white;
      &:hover,
      &:active {
        &:not(.disableHover):not([disabled]) {
          border-color: $color-secondary-dark;
        }
      }
    }

    &.gray {
      border-color: $color-gray-100;
      background-color: $color-white;
      &:hover,
      &:active {
        &:not(.disableHover):not([disabled]) {
          border-color: $color-gray-200;
        }
      }
    }

    &.disabled {
      background-color: $color-gray-50;
      border-color: $color-gray-100;
      color: $color-gray-200;
      cursor: not-allowed;
    }
  }

  &.icon {
    transition: fill 100ms ease;
    display: flex;
    align-items: center;

    &.primary {
      fill: $color-primary-main;

      &:hover,
      &:active {
        &:not(.disableHover):not([disabled]) {
          svg {
            fill: $color-primary-dark;
          }
        }
      }
    }

    &.secondary {
      fill: $color-secondary-main;

      &:hover,
      &:active {
        &:not(.disableHover):not([disabled]) {
          svg {
            fill: $color-secondary-dark;
          }
        }
      }
    }

    &.gray {
      svg {
        fill: $color-gray-200;
      }
    }

    &.default {
      svg {
        fill: $color-black;
      }

      &:hover,
      &:active {
        &:not(.disableHover):not([disabled]) {
          svg {
            fill: $color-gray-200;
          }
        }
      }
    }

    &.disabled {
      fill: $color-gray-100;
      cursor: not-allowed;
    }
  }

  &.slimPadding {
    padding: $spacing-12;
  }

  &.fullWidth {
    display: block;
    width: 100%;
    max-width: 100%;
  }

  .spinnerContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    justify-content: center;
    height: 24px;
    width: 24px;
    transform: translate(-12px, -12px);
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .isLoading {
    visibility: hidden;
  }
}
