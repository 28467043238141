$font-family: 'Walsheim', 'Helvetica Neue', 'Arial', 'Sans Serif';

$font-sizes-level: (
  level1: (
    $breakpoint-xs: 36px,
    $breakpoint-sm: 40px,
    $breakpoint-md: 60px,
  ),
  level2: (
    $breakpoint-xs: 32px,
    $breakpoint-sm: 32px,
    $breakpoint-md: 40px,
  ),
  level3: (
    $breakpoint-xs: 24px,
    $breakpoint-sm: 24px,
    $breakpoint-md: 24px,
  ),
  level4: (
    $breakpoint-xs: 20px,
    $breakpoint-sm: 20px,
    $breakpoint-md: 20px,
  ),
  level5: (
    $breakpoint-xs: 16px,
    $breakpoint-sm: 16px,
    $breakpoint-md: 16px,
  ),
);

$font-lines-level: (
  level1: (
    $breakpoint-xs: 49px,
    $breakpoint-sm: 49px,
    $breakpoint-md: 66px,
  ),
  level2: (
    $breakpoint-xs: 42px,
    $breakpoint-sm: 42px,
    $breakpoint-md: 49px,
  ),
  level3: (
    $breakpoint-xs: 35px,
    $breakpoint-sm: 35px,
    $breakpoint-md: 35px,
  ),
  level4: (
    $breakpoint-xs: 28px,
    $breakpoint-sm: 28px,
    $breakpoint-md: 28px,
  ),
  level5: (
    $breakpoint-xs: 24px,
    $breakpoint-sm: 24px,
    $breakpoint-md: 24px,
  ),
);

$font-sizes-body: (
  body1: 16px,
  body2: 14px,
  body3: 12px,
);

$font-lines-body: (
  body1: 24px,
  body2: 24px,
  body3: 21px,
);

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
