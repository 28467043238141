@import "src/styles";
.footer {
  // make the footer be always be at the bottom of the page
  position: sticky;
  top: 100%;

  padding: $spacing-48 0;
  background-color: $color-primary-main;
  background-image: url('/images/footer-pattern-top.png'),
    url('/images/footer-pattern-bottom.png');
  background-position:
    right top,
    left bottom;
  background-repeat: no-repeat, no-repeat;
  overflow: hidden;

  @include breakpoint-md {
    padding-top: $spacing-64;
  }
}

.container {
  display: grid;
  grid-template-rows: auto $spacing-64 auto $spacing-64 1fr;

  @include breakpoint-sm {
    grid-template-rows: auto $spacing-64 auto $spacing-160 1fr;
  }
}

.columnHeading {
  margin-bottom: $spacing-24;
}

.logo {
  grid-row: 1;
  display: flex;
  justify-self: start;
  gap: $spacing-48;

  .logoIcon {
    width: 122px;
  }

  .bCorpLogoIcon {
    width: 30px;

    @include breakpoint-lg {
      width: 35px;
    }
  }

  svg {
    fill: $color-white;
  }
}

.linksSection {
  display: grid;
  gap: $spacing-24;
  grid-row: 3;
  grid-template-rows: repeat(4, auto);
  grid-template-columns: repeat(2, 1fr);

  @include breakpoint-sm {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }

  @include breakpoint-lg {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr) 2fr;
  }

  .linkColumn:nth-child(1) {
    grid-row: 1;
  }

  .linkColumn:nth-child(2) {
    grid-row: 2;

    @include breakpoint-sm {
      grid-row: 1;
    }
  }

  .linkColumn:nth-child(3) {
    grid-row: 3;

    @include breakpoint-sm {
      grid-row: 1;
    }
  }

  .subscribeColumn {
    grid-row: 4;
    grid-column: span 2;

    @include breakpoint-sm {
      grid-row: 2;
    }

    @include breakpoint-lg {
      grid-row: 1;
      grid-column: 4;
    }

    p:not(:last-child) {
      margin-bottom: $spacing-24;
    }
    p:last-child {
      margin-top: $spacing-12;
    }
  }

  & .linkColumn {
    li {
      margin-bottom: $spacing-16;

      .columnLink {
        font-size: map-get($font-sizes-body, 'body1');
      }
    }
  }
}

.legalSection {
  display: grid;
  grid-row: 5;
  grid-template-columns: auto auto;
  grid-template-rows: auto repeat(2, $spacing-24);
  gap: $spacing-32;

  @include breakpoint-sm {
    grid-template-rows: repeat(2, 1fr);
  }

  .payments {
    grid-row: 3;

    @include breakpoint-sm {
      grid-row: 1;
    }
  }

  ul {
    grid-row: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: $spacing-24;
    grid-column: 1;

    @include breakpoint-sm {
      grid-row: 2;
      flex-direction: row;
    }

    .legalLink {
      font-weight: $font-weight-bold;
    }
  }

  .socialLinks {
    grid-row: 2;

    @include breakpoint-sm {
      grid-row: 1;
      justify-self: end;
    }
  }

  .localeSelect {
    grid-row: 3;
    grid-column: 2;
    justify-self: end;

    @include breakpoint-sm {
      grid-row: 2;
    }
  }
}
