@import "src/styles";
.container {
  background-color: $color-white;
  box-shadow: $box-shadow-2;
  position: sticky;
  top: 0;
  z-index: $z-index-4;
  min-height: $spacing-navBar;
}

.navbar {
  @include containerLayout();
  height: 100%;
  width: 100%;
  padding: {
    top: $spacing-12;
    bottom: $spacing-12;
  }
  display: grid;
  grid-gap: $spacing-12;
  grid-template-columns: auto 1fr;

  @include breakpoint-md {
    display: flex;
    align-items: center;
  }
}

.link {
  display: flex;
  align-items: center;
  max-width: 110px;

  svg {
    width: 100%;
    fill: $color-primary-main;
  }
}

.rightSlot,
.centerSlot {
  display: flex;
  align-items: center;

  @include breakpoint-md {
    margin-left: auto;
  }
}

.rightSlot {
  justify-self: end;
}

.centerSlot {
  grid-row: 2 / span 1;
  grid-column: 1 / span 2;
}
