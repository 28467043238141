@import "src/styles";
.accordionItem {
  & .accordionContent {
    overflow: hidden;
    white-space: pre-wrap;
  }

  & .accordionTrigger {
    display: flex;
    align-items: baseline;
    text-align: left;
  }
}
